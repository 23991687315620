<template lang="pug">
  .container
    .ui.segments.mp.expandable.table
     .ui.header.segment
       .ui.grid
         .three.wide.column Report
         .column Description


     .ui.row.segment
       .ui.grid
         .three.wide.column
           a(href='/reports/trades-diary') Trades Diary

         .thirteen.wide.column
           | Trades diary report to track resources allocation accross projects
</template>

<script>
export default {
  name: "Reports"
};
</script>
