<template lang="pug">
  .wide.container.h-full
    app-panel.h-full(compact)
      .bg-grey-80.px-8.py-4
        a.text-white.p-2(class="hover:bg-grey-70 hover:text-white" href="/reports/trades-diary.xlsx", target="_blank")
          app-icon.mr-4(icon="download", colour="white")
          span Export

      ag-grid-vue(ref='grid'
                  style="width: 100%; height: 84%;"
                  class="ag-theme-balham report-grid"
                  rowHeight='28'
                  :columnDefs="columnDefs"
                  :rowData="rowData"
                  :suppressRowTransform="true"
                  :rowClassRules="rowClassRules"
                  :components='gridComponents'
                  rowSelection="single"
                  width='20px')
</template>

<script>
import format from "date-fns/format";
import eachDayOfInterval from "date-fns/eachDayOfInterval";
import isSaturday from "date-fns/isSaturday";
import isSunday from "date-fns/isSunday";
import parseISO from "date-fns/parseISO";
import { AgGridVue } from "ag-grid-vue";

function CustomTooltip() {}

CustomTooltip.prototype.init = function init(params) {
  const eGui = document.createElement("div");
  const color = params.color || "#acacac";
  const { data } = params.api.getDisplayedRowAtIndex(params.rowIndex);

  eGui.classList.add("custom-tooltip");
  eGui.style["background-color"] = color;
  eGui.innerHTML = `<p><span class"name">${data.project}</span></p>`;

  if (data.projectsCount)
    eGui.innerHTML += `<p><span>Projects: </span>${data.projectsCount}</p>`;
  if (data.tasksCount)
    eGui.innerHTML += `<p><span>Tasks: </span>${data.tasksCount}</p>`;

  this.eGui = eGui;
};

CustomTooltip.prototype.getGui = function getGui() {
  return this.eGui;
};

function projectLinkCellRender(params) {
  const {
    data: { projectPath },
    value
  } = params;

  if (projectPath) {
    return `<a href="${projectPath}">${value}</a>`;
  }
  return value;
}
function taskLinkCellRenderer(params) {
  const {
    data: { taskPath },
    value
  } = params;

  if (taskPath) {
    return `<a href="${taskPath}">${value}</a>`;
  }
  return value;
}

export default {
  name: "TradesDiary",

  components: {
    AgGridVue
  },

  data() {
    return {
      columnDefs: null,
      rowData: null,
      rowClassRules: {
        "task-type-row": params => params.data.isTaskType,
        "task-row": params =>
          !(params.data.projectTitle || params.data.isTaskType)
      },
      gridComponents: {
        customTooltip: CustomTooltip
      }
    };
  },

  beforeMount() {
    const columnDefs = [
      {
        headerName: "Date",
        pinned: "left",
        lockPinned: true,
        children: [
          {
            headerName: "Day",
            pinned: "left",
            lockPinned: true,
            children: [
              {
                pinned: "left",
                lockPinned: true,
                resizable: true,
                headerName: "Project",
                autoHeight: true,
                field: "project",
                tooltipField: "project",
                tooltipComponent: "customTooltip",
                cellRenderer: projectLinkCellRender,
                rowSpan(params) {
                  return params.data.projectTitle ? params.data.tasksCount : 1;
                },
                cellClassRules: {
                  project: params => params.data.projectTitle
                  // 'task-type': params => params.data.isTaskType,
                  // task: params => !(params.data.projectTitle || params.data.isTaskType),
                }
              },
              {
                pinned: "left",
                lockPinned: true,
                resizable: true,
                autoHeight: true,
                headerName: "Task",
                field: "task",
                tooltipField: "task",
                filter: true,
                cellClass: "task",
                cellStyle(params) {
                  return {
                    backgroundColor: params.data.colour
                  };
                },
                cellRenderer: taskLinkCellRenderer
              }
            ]
          }
        ]
      }
    ];

    const headerClass = date => {
      if (isSaturday(date) || isSunday(date)) {
        return "weekend-header";
      }
      return "";
    };

    fetch("/reports/trades-diary.json")
      .then(resp => resp.json())
      .then(data => {
        const dates = eachDayOfInterval({
          start: parseISO(data.dates.min),
          end: parseISO(data.dates.max)
        });

        const projectColumn = columnDefs[0].children[0].children[0];
        projectColumn.colSpan = function colSpan(params) {
          return params.data.isTaskType ? dates.length + 2 : 1;
        };

        this.rowData = data.rows;

        dates.forEach((date, idx) => {
          const dateFormat = this.$store.state.dateFormats.dateFns;
          const dateStr = format(date, dateFormat);
          const day = format(date, "ddd");
          const even = idx % 2 ? "even" : "";

          const colDef = {
            headerName: dateStr,
            headerClass: headerClass(date),
            children: [
              {
                headerName: day,
                headerClass: headerClass(date),
                children: [
                  {
                    headerName: "",
                    field: dateStr,
                    cellStyle(params) {
                      if (params.value) {
                        return { backgroundColor: params.data.colour };
                      }

                      return {};
                    },
                    cellClass: `task-date ${even}`,
                    width: 95
                  }
                ]
              }
            ]
          };

          columnDefs.push(colDef);
        });
        this.columnDefs = columnDefs;
      });
  }
};
</script>

<style lang="scss">
@import "ag-grid-community/dist/styles/ag-grid.css";
@import "ag-grid-community/dist/styles/ag-theme-balham.css";

$task_color: #444;
$even_column_color: #d6d6d6;

.custom-tooltip {
  position: absolute;
  min-width: 150px;
  min-height: 30px;
  border: 1px solid cornflowerblue;
  overflow: hidden;
  pointer-events: none;
  transition: opacity 0.1s;
}

.custom-tooltip.ag-tooltip-hiding {
  opacity: 0;
}

.custom-tooltip p {
  margin: 2px;
  white-space: nowrap;
}

.custom-tooltip p:first-of-type {
  font-weight: bold;
}

.project {
  color: #494c4f;
  background-color: #f7f4f4 !important;
  font-weight: 500;
  font-style: italic;
}

.task,
.project {
  a {
    color: $task_color;
    &:hover {
      color: #0c88db;
      text-decoration: underline;
    }
  }
}

.task-type-row {
  background-color: #dcdcdc !important;
  font-size: 14px;
  font-weight: bold;
  color: $task_color;
}

.task-row {
  background-color: #ffa500b5;
}

.task-date {
  color: $task_color;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  &.even {
    background-color: change-color($even_column_color, $alpha: 0.15);
  }
}

.weekend-header {
  background: var(--grey-40);
}

.ag-column-hover {
  background-color: change-color($even_column_color, $alpha: 0.3);
  border-left: 1px solid change-color($even_column_color, $alpha: 0.8) !important;
  border-right: 1px solid change-color($even_column_color, $alpha: 0.8) !important;
}
</style>
